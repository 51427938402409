import { IChecklist } from '@/classes/Checklist/Checklist'
import { ActionContext } from 'vuex'
import { State } from './state'

const URL_ACTION = '/apis/checklist/'
const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}get/all`,
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_ALL', data)
    return data
  },
  async create ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: IChecklist) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}create`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('PUSH', data)
    return data
  },
  async update ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: IChecklist) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}update/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE', data)
    return data
  },
  async delete ({ commit, dispatch }: ActionContext<State, string>, payload: IChecklist) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}delete/${payload.id}`,
          method: 'DELETE',
          data: payload
        },
        { root: true }
      )
      // @ts-ignore
      commit('DELETE', payload)
    } catch (error) {
      await dispatch('app/notification', { content: 'No se ha podido eliminar el registro', type: 'error' }, { root: true })
      throw error
    }
  }
}
export default actions
