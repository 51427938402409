/* eslint-disable camelcase */
import { ActionContext } from 'vuex'
import { State } from './state'
import { USER_TYPES } from '../auth/USER_TYPES'
import objToFormData from '@/utils/objToFormData'

export const actions = {

  async init ({ commit, dispatch }: ActionContext<State, any>) {
    try {
      commit(
        'app/UPDATE_LOADER',
        {
          loader: true,
          message: 'Descargando Recursos'
        },
        { root: true }
      )
      await dispatch('loadServices')
      // await dispatch('loadUsersByUserLogged')
      await dispatch('user/loadUserByAccount')
      await dispatch('jimiiot/init', {}, { root: true })
    } catch (error) {
      console.error(error)
      commit(
        'app/ADD_LOG',
        {
          title: 'OBTENIENDO RECURSOS',
          color: 'error',
          message: error
        },
        { root: true }
      )
    } finally {
      commit(
        'app/UPDATE_LOADER',
        {
          loader: false,
          message: 'Descargando Recursos'
        },
        { root: true }
      )
    }
  },
  async loadUsersByUserLogged ({ commit, dispatch, rootGetters, rootState }: ActionContext<State, any>) {
    try {
      if (rootGetters['auth/roleId'] === USER_TYPES.MONITOREO) {
        const usersFromEnterprise = await dispatch('loadUsersChild', rootState.auth.user_date.id_user)
        commit(
          'administration/SET_USERS',
          usersFromEnterprise[0].childUser,
          { root: true }
        )
      }
      if (rootGetters['auth/roleId'] === USER_TYPES.CLIENTE || rootGetters['auth/roleId'] === USER_TYPES.USER_MIRROR) {
        const usersMirrors = await dispatch('loadUsersMirrors', rootState.auth.user_date.id_user)
        // usersMirrors = rootState.auth.user_date.id_user_mirror ? usersMirrors[0].childUser.filter((user:any) => user.userType === USER_TYPES.CONDUCTOR) : usersMirrors[0].childUser
        commit(
          'administration/SET_USERS',
          usersMirrors[0].childUser,
          { root: true }
        )
      }
      if (rootGetters['auth/roleId'] === USER_TYPES.ADMINISTRADOR) {
        await dispatch('loadUsers')
      }
    } catch (error) {
      await dispatch('app/notifyToast', { msj: 'Error al consultar', type: 'error' }, { root: true })
    }
  },
  async loadUsers ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/get_users_enterprise',
        method: 'GET'
      },
      { root: true }
    )
    commit(
      'administration/SET_USERS',
      data,
      { root: true }
    )
  },
  async loadUsersByEmpRastreoDistribuitor ({ dispatch }: ActionContext<State, any>, id_user:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/get_enterprise_by_distribuitor/${id_user}`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async loadUsersChild ({ dispatch }: ActionContext<State, any>, userID: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/get_users_child',
        method: 'POST',
        data: { userID }
      },
      { root: true }
    )
    return data
  },
  async loadUsersMirrors ({ dispatch }: ActionContext<State, any>, userID: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/get_users_mirrors',
        method: 'POST',
        data: { userID }
      },
      { root: true }
    )
    return data
  },
  async createUnitsAccess ({ dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/create_units_access_profile',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async createUser ({ dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/create_user',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async updateUser ({ dispatch }: ActionContext<State, any>, payload: any) {
    const { childUser, ...rest } = payload
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/update_user',
        method: 'POST',
        data: rest
      },
      { root: true }
    )
    return data
  },
  async updateImagesEnterprise ({ dispatch }: ActionContext<State, any>, payload: any) {
    const formData = objToFormData(payload)
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/update_images_enterprise',
        method: 'POST',
        data: formData
      },
      { root: true }
    )
    return data
  },
  async deleteUser ({ dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/delete_user',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
  },

  async deleteUserPermanently ({ commit, dispatch }: ActionContext<State, any>, pk: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/delete_user_permament/${pk}`,
        method: 'DELETE'
      },
      { root: true }
    )
  },
  async loadServices ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/get_services',
        method: 'GET'
      },
      { root: true }
    )
    commit(
      'administration/SET_SERVICES',
      data,
      { root: true }
    )
  },
  async loadUsersDelete ({ dispatch }: ActionContext<State, any>, payload: { limit: number; offset: number; } = { limit: 10, offset: 0 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/get_users_inactive/?limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async reactivatedUser ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/activate_user',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
  },
  async createBillingInfo ({ dispatch, rootState }: ActionContext<State, any>, payload: any) {
    const formData = objToFormData(payload)
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/billing_info',
        method: 'POST',
        data: formData
      },
      { root: true }
    )
    rootState.auth.user_date.billing_info = data
    return data
  },
  async updateBillingInfo ({ dispatch, rootState }: ActionContext<State, any>, payload: any) {
    const formData = objToFormData(payload)
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/billing_info/${payload.id}`,
        method: 'PUT',
        data: formData
      },
      { root: true }
    )
    rootState.auth.user_date.billing_info = data
    return data
  }
}

export default actions
