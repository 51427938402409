import { PLAN_CAMERA_TYPE, TYPE_PLAN } from '@/store/catalogs/PLAN_CAMERA_TYPE'

interface CptProperties {
    [key: string]: any;
    EhomeID: string;
    uniqueId: string;
    devName: string;
    EhomeKey: string;
    unitconsole_id: number;
    imei: string;
    channels: string;
    jimiIotModel: number;
    allowedAudioChannels: string;
}

interface ICamera {
    id: number;
    status: boolean;
    cpf_name: string;
    cpf_type: number;
    cpt_properties: CptProperties;
    cpt_time_used: number;
    last_conection: string;
    unitconsole: number;
}

export default class Camera implements ICamera {
    id: number;
    status: boolean;
    cpf_name: string;
    cpf_type: number;
    cpt_properties: CptProperties;
    cpt_time_used: number;
    unitconsole: number;
    last_conection: string;

    constructor (data: ICamera) {
      this.id = data.id
      this.status = data.status
      this.cpf_name = data.cpf_name
      this.cpf_type = data.cpf_type
      this.cpt_properties = data.cpt_properties
      this.cpt_time_used = data.cpt_time_used
      this.unitconsole = data.unitconsole
      this.last_conection = 'Sin reportar'
    }

    getPercentage (plan_camera: TYPE_PLAN) {
      const plan_camera_find = PLAN_CAMERA_TYPE.find(plan_cam => plan_cam.id === plan_camera)
      return (this.cpt_time_used / (plan_camera_find?.seconds ?? 1)) * 100
    }

    getMinutesUsed () {
      return parseFloat((this.cpt_time_used / 60).toFixed(1))
    }

    setSecondsUsed (seconds: number) {
      this.cpt_time_used = this.cpt_time_used + seconds
    }

    getHVEHomeID () {
      return this.cpt_properties?.EhomeID
    }
}
