/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionContext } from 'vuex/types/index'
import { State } from './state'
import JimiIothubSDK from '@/submodules/iothubsudsdk/dist'
import { IJimiIotDVRLastStatus } from '@/interfaces/JimiIot.interfaces'
import strRangeToNumbers from '@/utils/strRangeToNumbers'
import Camera from '@/classes/Camera/Camera'
import UnitConsoleData from '../packages/ReportItem/ReportItemData'
import { CAMERA_TYPE } from '../catalogs/CAMERA_TYPE'

export default {
  init ({ dispatch }: ActionContext<State, string>) {
    dispatch('initSDK')
  },
  initSDK ({ commit, rootState }: ActionContext<State, string>) {
    const sdk = new JimiIothubSDK({
      iothubServerIp: process.env.VUE_APP_JIMI_IOT_SERVER_HOST!,
      iothubCustomerServerInterfaceHost: process.env.VUE_APP_JIMI_IOT_CUSTOM_SERVER!,
      // @ts-ignore
      useHttps: true,
      // @ts-ignore
      debug: rootState.app.IS_DEV,
      usePortsAsUrl: true
    })
    commit('SET_STATE', { sdk })
  },
  async getDevicesStatusByIds ({ state }: ActionContext<State, string>, imeis: string[]): Promise<IJimiIotDVRLastStatus> {
    if (!state.sdk) return {}

    const { data } = await state.sdk.dvr.get_dvr_upload_jc450_status_last([], {
      imeis
    })

    return data
  },
  async getVideoChannelsJimiiot ({ rootState, state }: ActionContext<State, string>, payload: {
    camera: Camera;
    unitconsole: UnitConsoleData<unknown, unknown>;
    currChannels: any[];
  }) {
    const channels = strRangeToNumbers(payload.camera.cpt_properties.channels)
    const allowedAudioChannels = new Set(strRangeToNumbers(payload.camera.cpt_properties.allowedAudioChannels))
    const channelsLinks = state.sdk?.dvr.getChannelsLinks(payload.camera.cpt_properties.imei, channels)!

    const cameras = channelsLinks.map(({ channelId, link }) => ({
      id_camera: `${channelId}`,
      cam_name: `${channelId}`,
      cam_type: CAMERA_TYPE.FLV,
      cam_url: link,
      cam_edit: false,
      cam_delete: false,
      cam_data: {
        allowedAudioChannels
      },
      device: null,
      fk_camera_properties: 0
    }))

    // @ts-ignore
    const unitConsoleData: UnitConsoleData<unknown, unknown> = rootState.travel.unitsRegisters.find((obj: UnitConsoleData<unknown, unknown>) => obj.id === payload.unitconsole.id)
    if (unitConsoleData) {
      unitConsoleData.setCameraChannels(cameras)
    }
  }
}
